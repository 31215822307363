import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import MyNavbar from "./MyNavbar"
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Jumbotron from "react-bootstrap/Jumbotron"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Nav from "react-bootstrap/Nav"
import Img from "gatsby-image"

export default ({ children }) => <>
<MyNavbar fixed="top" />
<Image className="w-100 mb-0" srcset={["/static/shield-1519642_1920-e9876b9d130e3d5094826a629593b6d1.jpg","/static/93172b61e9f730ff8189e40074b2dd1c/bc3a8/english-2724442_1920.jpg"][Math.floor(Math.random() * 2)]} fluid />
<Jumbotron fliud>
  <h1>Hello, world!</h1>
  <p>
    This is a simple hero unit, a simple jumbotron-style component for calling
    extra attention to featured content or information.
  </p>
  <p>
    <Button variant="primary">Learn more</Button>
  </p>
</Jumbotron>
<Container className="pt-3">
{children}
</Container>
<Jumbotron className="mb-0" fluid>
<Container>
<h2>{process.env.SITE_NAME}</h2>
<Row className="text-left">
<Col>
<Nav defaultActiveKey="#" className="flex-column">
  <Nav.Link eventKey="disabled" disabled>Services</Nav.Link>
  <Nav.Link href="#">Private Lessons</Nav.Link>
  <Nav.Link href="#">Group Lessons</Nav.Link>
  <Nav.Link href="#">Corporate Classes</Nav.Link>
  <Nav.Link href="#">Intensive Course</Nav.Link>
  <Nav.Link href="#">Test Prep</Nav.Link>
</Nav>
</Col>
<Col>
<Nav defaultActiveKey="#" className="flex-column">
  <Nav.Link eventKey="disabled" disabled>Link</Nav.Link>
</Nav>
</Col>
<Col>
<Nav defaultActiveKey="#" className="flex-column">
  <Nav.Link eventKey="disabled" disabled>Services</Nav.Link>
  <Nav.Link href="#">Active</Nav.Link>
  <Nav.Link href="#">Active</Nav.Link>
</Nav>
</Col>

</Row>
  </Container>
</Jumbotron>
<Row className="p-3">
<Col>
Copyright &copy; {(new Date()).getFullYear()} {process.env.SITE_NAME}
</Col>
</Row>
  </>
