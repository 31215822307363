import React, { Component } from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

export default class MyNavbar extends Component {
  render() {
    return <>
<Navbar bg="light" expand="lg" fixed={this.props.fixed}>
  <Navbar.Brand href={`${process.env.SITE_URL}/`}>{process.env.SITE_NAME}</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Lessons" id="basic-nav-dropdown">
        <NavDropdown.Item href={`${process.env.SITE_URL}/services/private-lessons`}>Private Lessons</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href={`${process.env.SITE_URL}/services/group-lessons`}>Group Lessons</NavDropdown.Item>
      </NavDropdown>
      <Nav.Link href={`${process.env.SITE_URL}/services/corporate-classes`}>Corporate Classes</Nav.Link>
      <NavDropdown title="Courses" id="basic-nav-dropdown">
        <NavDropdown.Item href={`${process.env.SITE_URL}/services/special-course-test-prep`}>IELTS Test Prep</NavDropdown.Item>
        <NavDropdown.Item href={`${process.env.SITE_URL}/services/special-course-test-prep`}>TOEIC, TOEFL Test Prep</NavDropdown.Item>
        <NavDropdown.Item href={`${process.env.SITE_URL}/services/special-course-test-prep`}>Special Courses</NavDropdown.Item>
      </NavDropdown>

      <Nav.Link href={`${process.env.SITE_URL}/contact/`}>Contact Us</Nav.Link>
    </Nav>
    <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form>
  </Navbar.Collapse>
</Navbar>
    </>
  }
}
